@import "../../node_modules/react-vis/dist/style.css";
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import '../../node_modules/semantic-ui-css/semantic.min.css';
@import '../../node_modules/react-dropdown/style.css';
@import '../../node_modules/react-select/dist/react-select.css';
@import '../../node_modules/react-virtualized/styles.css';
@import '../../node_modules/react-virtualized-select/styles.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inline-code {
  padding: 1rem 2rem;
  overflow-x: hidden;
}

.inline-code.container>div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.custom-tooltip {
  display: inline-block;
  color: rgb(3, 0, 0);
  width: 10%;
}

.mark-series-overrides {
  cursor: pointer;
}

.chart-tooltip-header {
  text-align: left;
  background-color: #282c34;
  color: white;
  padding: 3px 6px;
  width: auto;
}

.chart-tooltip-content {
  text-align: left;
  width: auto;
  background-color: white;
}

.chart-tooltip-content .symbol {
  padding: 2px;
  display: inline-block;
  border: 1px solid #080808;
  width: 60%;
}

.chart-tooltip-content .symbolValue {
  display: inline-block;
  border-color: #080808;
  padding: 2px;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  width: 40%;
}

.margin-sm {
  margin: 5px !important;
}

.margin-l-sm {
  margin-left: 5px !important;
}

.margin-r-sm {
  margin-right: 5px !important;
}

.margin-t-sm {
  margin-top: 5px !important;
}

.margin-b-sm {
  margin-bottom: 5px !important;
}